import SkeletonKurs from "./SkeletonKurs";

const HtmlKurs = ({html, kursTitle, kursColor, resetClick}) => {

    return (
        <SkeletonKurs
            kursTitle={kursTitle}
            kursColor={kursColor}
            resetClick={resetClick}>

            <div dangerouslySetInnerHTML={{__html: html}}/>

        </SkeletonKurs>
    );
};

export default HtmlKurs;
