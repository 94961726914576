import './App.css';
import Bildungsplaner from './components/Bildungsplaner';
import {useState} from 'react'
import EventoKurs from './components/struktur/kurse/EventoKurs';
import HtmlKurs from './components/struktur/kurse/HtmlKurs';

function App() {
    const [selectedItem, setSelectedItem]=useState(null);

    function handleClick(entry){
        setSelectedItem(entry);
    }
    function resetClick(){
        setSelectedItem(null);
    }

  return (
    <>
    {selectedItem ? (selectedItem.evento_id !== null ? (
        <EventoKurs
            eventoId={selectedItem.evento_id}
            kursTitle={selectedItem.name}
            kursColor={selectedItem.color}
            resetClick={resetClick}
        />
    ):(
        <HtmlKurs
            html={selectedItem.html_text}
            kursTitle={selectedItem.name}
            kursColor={selectedItem.color}
            resetClick={resetClick}
        />
    )) : (
        <Bildungsplaner handleClick={handleClick}/>
    )}
    </>
  );
}

export default App;
