const SkeletonKurs = ({kursTitle, kursColor, resetClick, children})=>{
    const courseStyle = {
        '--course-color': kursColor,
    };

    return(
        <div className="KursContainer" style={courseStyle}>
            <div className="inner-container">
                <h2 className="title" onClick={resetClick}>
                    {kursTitle}
                    <span className="backButton">
                        ✖
                    </span>
                </h2>
                <div className="kurs-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SkeletonKurs;
