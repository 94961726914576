import React, { useEffect, useState } from 'react';
import Kategorie from "./struktur/Kategorie";
import {fetchBildungsplanerData} from "../api/crm-api";

const Bildungsplaner = ({handleClick}) => {

    const [bildungsplanerData, setBildungsplanerData] = useState([]);

    useEffect(() => {
        const initBildungsplanerData = async () => {
            try {
                const data = await fetchBildungsplanerData();
                setBildungsplanerData(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        initBildungsplanerData();
    }, []);

    return (
        <div className="bpMain">
            {
                [1,2,3,4].map((col)=>(
                    <div className={"bpColumn bpc"+col} key={col}>
                    {
                        bildungsplanerData.filter((bildungsplanerEintrag) => bildungsplanerEintrag.column === col).map(
                            (bildungsplanerEintrag) => (
                                <Kategorie
                                    title={bildungsplanerEintrag.name}
                                    color={bildungsplanerEintrag.color}
                                    groups={bildungsplanerEintrag.items}
                                    key={bildungsplanerEintrag.name}
                                    handleClick={handleClick}/>
                            )
                        )
                    }
                    </div>
                ))
            }
        </div>
    );
};

export default Bildungsplaner;
