import SkeletonKurs from "./SkeletonKurs";

const EventoKurs = ({eventoId, kursTitle, kursColor, resetClick}) => {

    const rawHtml = 'eventoapp.apps.be.ch/restApi hat die Verbindung abgelehnt (CORS)';

    return (
        <SkeletonKurs
            kursTitle={kursTitle}
            kursColor={kursColor}
            resetClick={resetClick}>

            <iframe src={`https://www.idm.ch/idm/media/kursausschreibung/?navigation=hide#/uid/${eventoId}`} className="evento-kurs-iframe">
                <a href={`https://www.idm.ch/idm/media/kursausschreibung/?navigation=hide#/uid/${eventoId}`}>
                    Kurs ID {eventoId}
                </a>
            </iframe>


        </SkeletonKurs>
    );
};

export default EventoKurs;
